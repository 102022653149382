import React, { useEffect } from "react"
import SEO from "../../../components/Seo/Seo"
import { blogUrlMapper } from "../../../utils/blogUrlMapper"
import { navigate } from "gatsby"

const blogMetadata = {
  Title: "Flipbase Blog - Klantverhalen, Trends en meer",
  Keywords: [],
  Description:
    "Meer weten over Recruitment en Flipbase? Lees onze klantverhalen en recruitment-gerelateerde blogs.",
  Image: "/7-reasons-why-you-need-video-in-your-strategy.jpg",
}

export default () => {
  useEffect(() => {
    navigate("/blog/7-reasons-why-you-need-video-in-your-strategy")
  }, [])

  return (
    <SEO
      title={blogMetadata.Title}
      description={blogMetadata.Description}
      image={blogMetadata.Image}
      keywords={blogMetadata.Keywords}
    />
  )
}
